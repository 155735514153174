(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _select_input = require("./templates/select_input");

var _select_input2 = _interopRequireDefault(_select_input);

var _normal_input = require("./templates/normal_input");

var _normal_input2 = _interopRequireDefault(_normal_input);

var _dynamic_input = require("./templates/dynamic_input");

var _dynamic_input2 = _interopRequireDefault(_dynamic_input);

var _dynamic_input_field = require("./templates/dynamic_input_field");

var _dynamic_input_field2 = _interopRequireDefault(_dynamic_input_field);

var _button_input = require("./templates/button_input");

var _button_input2 = _interopRequireDefault(_button_input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FormFactory = function () {
    function FormFactory() {
        _classCallCheck(this, FormFactory);
    }

    _createClass(FormFactory, null, [{
        key: "addField",
        value: function addField(parentDivId, jsonField) {
            var parentDiv = $("#" + parentDivId);
            var divId = jsonField.name;

            if (parentDiv === undefined) return;

            FormFactory._fields.push(jsonField);

            parentDiv.append('<div id="' + divId + '"></div>');
            parentDiv.append('<div id="' + divId + '-children"></div>');

            var currentValue = null;

            if (FormFactory._current.hasOwnProperty(jsonField.name)) {
                currentValue = FormFactory._current[jsonField.name];
            }

            if (jsonField.type === 'select') {
                ReactDOM.render(React.createElement(_select_input2.default, { currentValue: currentValue, name: jsonField.name, label: jsonField.label,
                    help: jsonField.help,
                    options: jsonField.options }), document.getElementById(divId));

                if (currentValue !== null) {
                    FormFactory.addChildren(jsonField.name, currentValue);
                } else if (Object.keys(jsonField.options).length > 0) {
                    FormFactory.addChildren(jsonField.name, Object.keys(jsonField.options)[0]);
                }
            } else if (jsonField.type === 'input') {
                ReactDOM.render(React.createElement(_normal_input2.default, { currentValue: currentValue, name: jsonField.name, label: jsonField.label, help: jsonField.help }), document.getElementById(divId));
            } else if (jsonField.type === 'dynamic_input') {
                ReactDOM.render(React.createElement(_dynamic_input2.default, { name: jsonField.name, label: jsonField.label, help: jsonField.help }), document.getElementById(divId));

                $('#' + divId).append('<div id="' + divId + '-options"></div>');

                if (currentValue !== null && Array.isArray(currentValue)) {
                    currentValue.map(function (value) {
                        FormFactory.addDynamicField(jsonField.name, value);
                    });
                } else {
                    FormFactory.addDynamicField(jsonField.name);
                }
            } else if (jsonField.type === "button") {
                ReactDOM.render(React.createElement(_button_input2.default, { label: jsonField.label }), document.getElementById(divId));
            }
        }
    }, {
        key: "addChildren",
        value: function addChildren(name, value) {
            if (FormFactory._childFields.hasOwnProperty(name)) {
                FormFactory._childFields[name].map(function (value) {
                    if (FormFactory._dynamicFields.hasOwnProperty(value)) {
                        FormFactory._dynamicFields[value] = 0;
                    }
                });
            }
            FormFactory._childFields[name] = [];
            $('#' + name + '-children').empty();

            FormFactory._fields.map(function (field) {

                if (field.name == name && field.hasOwnProperty('onchange')) {
                    field.onchange.map(function (childField) {
                        childField.if.map(function (ifField) {
                            if (ifField == value) {
                                FormFactory._childFields[name].push(childField.name);
                                FormFactory.addField(name + "-children", childField);
                            }
                        });
                    });
                }
            });
        }
    }, {
        key: "addDynamicField",
        value: function addDynamicField(name, value) {
            if (!FormFactory._dynamicFields.hasOwnProperty(name)) {
                FormFactory._dynamicFields[name] = 0;
            }

            var count = FormFactory._dynamicFields[name];
            var parentDiv = $("#" + name + "-options");
            var divId = name + '-' + count;

            if (parentDiv === undefined) return;

            parentDiv.append('<div id="' + divId + '"></div>');

            ReactDOM.render(React.createElement(_dynamic_input_field2.default, { currentValue: value, name: name, divId: divId, index: count }), document.getElementById(divId));

            FormFactory._dynamicFields[name] = count + 1;
        }
    }, {
        key: "initCurrent",
        value: function initCurrent(current) {
            FormFactory._current = current;
        }
    }]);

    return FormFactory;
}();

exports.default = FormFactory;


FormFactory._fields = [];
FormFactory._dynamicFields = [];
FormFactory._childFields = [];
FormFactory._current = [];

},{"./templates/button_input":4,"./templates/dynamic_input":5,"./templates/dynamic_input_field":6,"./templates/normal_input":7,"./templates/select_input":8}],2:[function(require,module,exports){
"use strict";

var _form_factory = require("./form_factory");

var _form_factory2 = _interopRequireDefault(_form_factory);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initDynamicForm(parentDivId, json, current) {
    "use strict";

    _form_factory2.default.initCurrent(current);

    for (var i = 0; i < json.length; i++) {
        _form_factory2.default.addField(parentDivId, json[i]);
    }
};

$(document).ready(function () {
    "use strict";

    var dynamicForms = $("*[data-react-dynamic-form]");
    $.map(dynamicForms, function (value) {
        var div = $(value);
        var id = value.id;
        var fields = div.data('dynamic-form-fields');
        var current = div.data('dynamic-form-current');
        initDynamicForm(id, fields, current);
    });
});

},{"./form_factory":1}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * This class gives base methods to components.
 */
var BaseComponent = function (_React$Component) {
  _inherits(BaseComponent, _React$Component);

  function BaseComponent() {
    _classCallCheck(this, BaseComponent);

    return _possibleConstructorReturn(this, (BaseComponent.__proto__ || Object.getPrototypeOf(BaseComponent)).apply(this, arguments));
  }

  _createClass(BaseComponent, [{
    key: "_bind",


    /**
     * Binds methods of a class to make this available.
     *
     * @param methods An array of methods.
     */
    value: function _bind() {
      var _this2 = this;

      for (var _len = arguments.length, methods = Array(_len), _key = 0; _key < _len; _key++) {
        methods[_key] = arguments[_key];
      }

      methods.forEach(function (method) {
        return _this2[method] = _this2[method].bind(_this2);
      });
    }
  }]);

  return BaseComponent;
}(React.Component);

exports.default = BaseComponent;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _base_component = require("./base_component");

var _base_component2 = _interopRequireDefault(_base_component);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 */
var ButtonInput = function (_BaseComponent) {
    _inherits(ButtonInput, _BaseComponent);

    function ButtonInput() {
        "use strict";

        _classCallCheck(this, ButtonInput);

        return _possibleConstructorReturn(this, (ButtonInput.__proto__ || Object.getPrototypeOf(ButtonInput)).call(this));
    }

    /**
     * Renders the component.
     *
     * @returns {XML}
     */


    _createClass(ButtonInput, [{
        key: "render",
        value: function render() {
            "use strict";

            return React.createElement(
                "div",
                { className: "row" },
                React.createElement(
                    "div",
                    { className: "large-12 columns" },
                    React.createElement(
                        "button",
                        { className: "small button primary" },
                        this.props.label
                    )
                )
            );
        }
    }]);

    return ButtonInput;
}(_base_component2.default);

exports.default = ButtonInput;

},{"./base_component":3}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _base_component = require("./base_component");

var _base_component2 = _interopRequireDefault(_base_component);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 */
var DynamicInput = function (_BaseComponent) {
    _inherits(DynamicInput, _BaseComponent);

    function DynamicInput() {
        "use strict";

        _classCallCheck(this, DynamicInput);

        return _possibleConstructorReturn(this, (DynamicInput.__proto__ || Object.getPrototypeOf(DynamicInput)).call(this));
    }

    /**
     * Renders the component.
     *
     * @returns {XML}
     */


    _createClass(DynamicInput, [{
        key: "render",
        value: function render() {
            "use strict";

            return React.createElement(
                "div",
                { className: "row" },
                React.createElement(
                    "div",
                    { className: "large-12 columns" },
                    React.createElement(
                        "label",
                        null,
                        this.props.label,
                        "\xA0",
                        function () {
                            if (this.props.help !== undefined) {
                                return React.createElement(
                                    "a",
                                    { "data-open": this.props.help },
                                    React.createElement("i", { className: "fa fa-question-circle", "aria-hidden": "true" })
                                );
                            }
                        }.call(this)
                    )
                )
            );
        }
    }]);

    return DynamicInput;
}(_base_component2.default);

exports.default = DynamicInput;

},{"./base_component":3}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _base_component = require("./base_component");

var _base_component2 = _interopRequireDefault(_base_component);

var _form_factory = require("./../form_factory");

var _form_factory2 = _interopRequireDefault(_form_factory);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * This class renders an input with a add/remove input button.
 */
var DynamicInputField = function (_BaseComponent) {
    _inherits(DynamicInputField, _BaseComponent);

    function DynamicInputField() {
        "use strict";

        _classCallCheck(this, DynamicInputField);

        var _this = _possibleConstructorReturn(this, (DynamicInputField.__proto__ || Object.getPrototypeOf(DynamicInputField)).call(this));

        _get(DynamicInputField.prototype.__proto__ || Object.getPrototypeOf(DynamicInputField.prototype), "_bind", _this).call(_this, '_handleAddClick', '_handleRemoveClick');
        return _this;
    }

    /**
     * Handles the event when the add button was clicked.
     *
     * @param event
     * @private
     */


    _createClass(DynamicInputField, [{
        key: "_handleAddClick",
        value: function _handleAddClick(event) {
            "use strict";

            _form_factory2.default.addDynamicField(this.props.name);
        }
    }, {
        key: "_handleRemoveClick",


        /**
         * Handles the event when the remove button was clicked.
         * @param event
         * @private
         */
        value: function _handleRemoveClick(event) {
            "use strict";

            $("#" + this.props.divId).remove();
        }
    }, {
        key: "render",


        /**
         * Renders the component.
         *
         * @returns {XML}
         */
        value: function render() {
            "use strict";

            return React.createElement(
                "div",
                { className: "row" },
                React.createElement(
                    "div",
                    { className: "large-9 columns" },
                    React.createElement("input", { type: "text", name: this.props.name + "[]", defaultValue: this.props.currentValue })
                ),
                React.createElement(
                    "div",
                    { className: "large-3 columns" },
                    React.createElement(
                        "div",
                        { className: "small button-group" },
                        React.createElement(
                            "a",
                            { className: "button", href: "#", onClick: this._handleAddClick },
                            "Add"
                        ),
                        function () {
                            if (this.props.index !== 0) {
                                return React.createElement(
                                    "a",
                                    { className: "alert button", href: "#",
                                        onClick: this._handleRemoveClick },
                                    "Remove"
                                );
                            }
                        }.call(this)
                    )
                )
            );
        }
    }]);

    return DynamicInputField;
}(_base_component2.default);

exports.default = DynamicInputField;

},{"./../form_factory":1,"./base_component":3}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _base_component = require("./base_component");

var _base_component2 = _interopRequireDefault(_base_component);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 */
var NormalInput = function (_BaseComponent) {
    _inherits(NormalInput, _BaseComponent);

    function NormalInput() {
        "use strict";

        _classCallCheck(this, NormalInput);

        return _possibleConstructorReturn(this, (NormalInput.__proto__ || Object.getPrototypeOf(NormalInput)).call(this));
    }

    /**
     * Renders the component.
     *
     * @returns {XML}
     */


    _createClass(NormalInput, [{
        key: "render",
        value: function render() {
            "use strict";

            return React.createElement(
                "div",
                { className: "row" },
                React.createElement(
                    "div",
                    { className: "large-12 columns" },
                    React.createElement(
                        "label",
                        null,
                        this.props.label,
                        function () {
                            if (this.props.help !== undefined) {
                                return React.createElement(
                                    "a",
                                    { "data-open": this.props.help },
                                    React.createElement("i", { className: "fa fa-question-circle", "aria-hidden": "true" })
                                );
                            }
                        }.call(this),
                        React.createElement("input", { type: "text", name: this.props.name, defaultValue: this.props.currentValue })
                    )
                )
            );
        }
    }]);

    return NormalInput;
}(_base_component2.default);

exports.default = NormalInput;

},{"./base_component":3}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _base_component = require("./base_component");

var _base_component2 = _interopRequireDefault(_base_component);

var _form_factory = require("./../form_factory");

var _form_factory2 = _interopRequireDefault(_form_factory);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * This class renders an input with a add/remove input button.
 */
var SelectInput = function (_BaseComponent) {
    _inherits(SelectInput, _BaseComponent);

    function SelectInput() {
        "use strict";

        _classCallCheck(this, SelectInput);

        var _this = _possibleConstructorReturn(this, (SelectInput.__proto__ || Object.getPrototypeOf(SelectInput)).call(this));

        _get(SelectInput.prototype.__proto__ || Object.getPrototypeOf(SelectInput.prototype), "_bind", _this).call(_this, '_handleOnChange');
        return _this;
    }

    _createClass(SelectInput, [{
        key: "_handleOnChange",
        value: function _handleOnChange(event) {
            _form_factory2.default.addChildren(this.props.name, event.target.value);
        }

        /**
         * Renders the component.
         *
         * @returns {XML}
         */

    }, {
        key: "render",
        value: function render() {
            "use strict";

            var _this2 = this;

            return React.createElement(
                "div",
                { className: "row" },
                React.createElement(
                    "div",
                    { className: "large-12 columns" },
                    React.createElement(
                        "label",
                        null,
                        this.props.label,
                        function () {
                            if (this.props.help !== undefined) {
                                return React.createElement(
                                    "a",
                                    { "data-open": this.props.help },
                                    React.createElement("i", { className: "fa fa-question-circle", "aria-hidden": "true" })
                                );
                            }
                        }.call(this),
                        React.createElement(
                            "select",
                            { id: "type", name: this.props.name, onChange: this._handleOnChange, defaultValue: this.props.currentValue },
                            Object.keys(this.props.options).map(function (index) {
                                return React.createElement(
                                    "option",
                                    { name: index, key: index, value: index },
                                    _this2.props.options[index]
                                );
                            })
                        )
                    )
                )
            );
        }
    }]);

    return SelectInput;
}(_base_component2.default);

exports.default = SelectInput;

},{"./../form_factory":1,"./base_component":3}]},{},[2]);
